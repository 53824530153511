<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  watch: {
    $route() {
      sessionStorage.setItem("routerNow", this.$route.path);
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import url("./assets/css/reset.css");
</style>
